.Main-Div-Footer {
  /* padding-top: 25vh;  
  min-height: 90vh; */
  min-height: 25vw;
  background-color: var(--primary);
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}
.Lower-Div {
  min-height: 15vh;
  margin-left: 9%;
  margin-right: 9%;

  text-align: left;
}
.Upper-Div {
  min-height: 40vh;
  width: 100%;
  display: flex;
}
.Col1-Div {
  padding-top: 10vh;
  padding-left: 9%;
  padding-right: 9%;
  display: flex;
  width: 40%;
  min-height: 40vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Col2-Div {
  padding-top: 10vh;

  width: 34%;
  min-height: 40vh;
  text-align: left;
  padding-left: 25%;
}
.Col3-Div {
  padding-top: 10vh;

  width: 33%;
  min-height: 40vh;
  text-align: left;
  padding-left: 10%;
  padding-right: 9%;
}
.Logo-Image {
  width: 8rem;
}

.Main-Div-Footer .icon {
  color: var(--primary) !important;
  margin: 0;
  width: 75% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-duration: 1s;
}

.bg-black {
  background-color: var(--primary) !important;
}

.btn-outline-light:hover {
  background-color: white;
  color: var(--secondary) !important;
  border-color: var(--secondary);
}
.btn {
  background-color: white;
  transition: color 0.5s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-floating {
  width: 1.65rem;
  height: 1.65rem;
}
.Main-Div-Footer .m-1 {
  color: var(--primary) !important;
  background: white !important;
  transition: color 0.5s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.15s ease-in-out !important;
  width: 1.65rem;
  height: 1.65rem;
  margin: 0.3rem !important;
  border-style: solid !important;
  border-width: 0.125rem !important;
  border-color: white !important;
}
.Main-Div-Footer .m-1:hover {
  background-color: white;
  color: var(--primary) !important;
  border-color: var(--secondary) !important;
}
.Row-Header {
  font-size: 0.8rem;
}
.text-white {
  font-family: "Qanelas light";
  font-size: 0.8rem;
  color: #9497a1 !important;
}
.text-white:hover {
  cursor: pointer;
  color: var(--secondary) !important;
}
.Main-Div-Footer li {
  color: #9497a1;
  line-height: 1.5rem;
}
hr {
  color: #9497a1;
}
.term {
  width: 25%;
  text-align: right;
}
.privacy {
  text-align: right;

  width: 25%;
}
.copyright {
  width: 50%;
}
.copyprivacy {
  display: flex;
}

/* Change styles for span and cancel button on extra small screens */
@media only screen and (max-width: 700px) {
  .Upper-Div {
    min-height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Col1-Div {
    width: 100%;
  }
  .Col2-Div {
    width: 100%;
    padding-top: 0;

    min-height: auto;
    text-align: center;
    padding-left: 0%;
  }
  .Col3-Div {
    width: 100%;
    padding: 0;
    padding-top: 5vh;

    min-height: auto;
    text-align: center;
  }
  .Main-Div {
    height: fit-content;
  }
  .Lower-Div {
    height: fit-content;

    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .term {
    visibility: hidden;
    margin-left: 0%;
    width: 0%;
  }
  .privacy {
    visibility: hidden;
    width: 0%;

    margin-left: 0%;
  }

  .copyright {
    width: 100%;
  }
  .copyprivacy {
    display: flex;
  }
}
