form {
  text-align: center;
  color: var(--primary) !important;
}
.buttonBrochure {
  margin-top: 2rem !important;
  width: 15rem !important;
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}
.buttonBrochure:hover {
  margin-top: 2rem !important;
  width: 15rem !important;
  color: var(--secondary) !important;
  border-color: var(--primary) !important;
  background-color: var(--primary) !important;
}
