.FeaturedDiv {
  height: fit-content;
  display: flex;
  place-content: center;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

a {
  text-decoration: none;
}
.figure {
  text-align: left;
  font-family: "Qanelas";
  color: white;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 395px;
  padding: 20px 20px;
  background-color: rgba(0, 0, 0, 0.3);
  /* background-image: url("../../Images/Belle-Vie.png"); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* background-size: 100%; */
  /* border-radius: 7.5px; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
.figureOverlay {
  /* border-radius: 7.5px; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 1vw;
  width: 23%;
  height: 45%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.figureOverlay:hover {
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);

  transform: translateY(-1.5%);

  /* background-size: 175%; */
}
.figure:before,
.figure:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.figure:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 0;
}
.figure:after {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
.figure:hover {
  box-shadow: 0 25px 28px rgb(0 0 0 / 25%), 0 15px 10px rgb(0 0 0 / 22%);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);

  /* transform: translateY(-3%); */

  background-size: 175%;
}
.figure:hover:after {
  font-family: "Qanelas light";
  content: "An elevated way of life";
  background-color: rgba(217, 217, 217, 0.3);
  color: white;
  font-size: 1.5rem;
  z-index: 2;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.figure:hover .date {
  bottom: -59px;
}
.figure:hover .figcaption {
  transform: translateY(-155%);
}
.figure .date {
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  /* background-color: rgba(217, 217, 217, 0.3); */
  text-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.figure .date span {
  color: white;
  line-height: 1;
}
.figure .date span:first-child {
}
.figure .date span:last-child {
  font-weight: 400;
  font-size: 0.8rem;
}
.figure .figcaption {
  font-family: "Qanelas light";
  color: white;
  transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 1;
  margin-top: 2vw;
}
.figure .figcaption h4 {
  font-family: "Qanelas";
  color: var(--secondary);
  margin: 0 0 5px;
  font-size: 1.5rem;
  line-height: 1.35;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
}
.figure .figcaption h4 > span {
  font-size: 1.5rem;
}
.figure .figcaption p {
  margin: 0;
  line-height: 1.5;
}
/* Change styles for span and cancel button on extra small screens */
@media only screen and (max-width: 700px) {
  .FeaturedDiv {
    display: inline-block;
  }
  .figure {
    width: 100%;
    height: 100%;
    padding: 20px 20px;
  }
  .figureOverlay {
    margin: 3%;
    width: 94%;
    height: 35vw;
  }
  .figure .figcaption h4 > span {
    /* background-color: rgba(43, 60, 72, 0.7); */
    font-size: 2rem;
  }
  .figure .figcaption h4 {
    text-align: center;
  }
  .figure .figcaption p {
    visibility: collapse;
  }
  .figure .figcaption {
    margin-top: 9vw;
  }
  .figure .date {
    font-family: "Qanelas light";
    right: 5px;
    display: inline-block;
    padding: 2px 4px 2px 4px;
  }
  .figure .date span:first-child {
    /* font-size: 10px; */
    /* font-weight: 900; */
  }
  .figure .date span:last-child {
    font-size: 1rem;
    margin-left: 2px;
  }
  .figureOverlay:hover {
    transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: none;

    /* background-size: 175%; */
  }
  .figure:hover {
    background-size: cover;
  }
  .figure:hover:after {
    font-family: "Qanelas light";
    content: none;
    background-color: none;
    color: white;
    z-index: 2;
    transition: all 1s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .figure:hover .date {
    bottom: 6px;
  }
  .figure:hover .figcaption {
    transform: none;
  }
}
