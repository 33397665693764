.mainAboutHome {
  display: flex;
  height: fit-content;
}
.mainAboutHome .left {
  width: 60%;
}
.mainAboutHome .right {
  width: 40%;
  padding-right: 10%;
}
.mainAboutHome .body1 {
  font-family: "Qanelas" !important;

  font-size: 1.9rem;
  line-height: 2.4vw;
  text-align: justify;
}
.mainAboutHome .body2 {
  color: #9497a1;
  font-family: "Qanelas light";
  font-size: 1.35rem;
  line-height: 2vw;

  text-align: justify;
}
.mainAboutHome img {
  filter: saturate(75%);
  width: 86%;
}
.follow {
  margin-top: 3vw;
  margin-bottom: 3vw;
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.follow.active {
  position: sticky;
  top: 10vw;
  bottom: 10vw;
  transform: translateY(0);
  opacity: 1;
}
/* .body1 .follow.active {
  position: sticky;
  top: 10vw;
  bottom: 10vw;
  transform: translateY(0);
  opacity: 1;
} */
@media only screen and (max-width: 700px) {
  .mainAboutHome {
    display: inline;
  }
  .mainAboutHome .left {
    width: 100%;
  }
  .mainAboutHome .right {
    width: 100%;
    padding: 5%;
  }
  .mainAboutHome .body1 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .mainAboutHome .body2 {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  .follow {
    margin-top: 5vw;
  }
  .mainAboutHome img {
    width: 90%;
  }
}
