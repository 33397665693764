.mainGalleryDiv {
  display: flex;
  padding-left: 4rem;
  padding-right: 3rem;
  padding-top: 3rem;
}

.mainGalleryDiv .summaryDiv {
  text-align: justify;
  margin-top: 0rem;
  margin-left: 3rem;
  margin-right: 3rem;
  width: 40vw;
}

.mainGalleryDiv .summaryDiv .title {
  font-weight: bold;
  font-size: 1.5rem;
}
.mainGalleryDiv .buttonDiv {
  text-align: center;
}
.image-gallery {
  width: 50vw;
}
.image-gallery-slide .image-gallery-image {
  width: 100%;
  height: 20rem;
  object-fit: contain;
}
.image-gallery-content.fullscreen .image-gallery-image {
  height: 55vh;
  margin-bottom: 15vh;
  margin-top: 15vh;
}
@media only screen and (max-width: 700px) {
  .image-gallery {
    width: 94vw;
  }
  .mainGalleryDiv {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
    width: -webkit-fill-available;
  }
  .mainGalleryDiv .summaryDiv {
    text-align: justify;
    margin-top: 3rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin-bottom: 2rem;
    width: -webkit-fill-available;
  }
}
