header {
  width: 100vw;
  background: black;
  overflow: hidden;
}
.image1 {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.7;
}
.image2 {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.7;
}
.image3 {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.7;
}
.AnimationDiv {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  color: white;
  width: 80%;
  height: 40%;
}

/* Change styles for span and cancel button on extra small screens */
@media only screen and (max-width: 700px) {
  .image1 {
    object-position: 25% 0vw;
  }
  .image2 {
    height: 108vh;
    object-position: 0vw -7vw;
  }

  .image3 {
    object-position: 25% 0vw;
  }
  .AnimationDiv {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    color: white;
    width: 80%;
    height: 40%;
  }
}
