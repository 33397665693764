@font-face {
  font-family: "Qanelas";
  src: url("../../font/Qanelas-Medium.otf");
  unicode-range: U+0020-007F;
}
@font-face {
  font-family: "Qanelas";
  src: url("../../font/Qanelas-Bold.otf");
  font-weight: bold;
  unicode-range: U+0020-007F;
}
/* @font-face {
  font-family: "Qanelas italics";
  src: url("../../font/Qanelas-MediumItalic.otf");
}
@font-face {
  font-family: "Qanelas italics";
  src: url("../../font/Qanelas-BoldItalic.otf");
  font-weight: bold;
} */
@font-face {
  font-family: "Qanelas light";
  src: url("../../font/Qanelas-Light.otf");
  unicode-range: U+0020-007F;
}

.App {
  font-family: "Qanelas";
  text-align: center;
  width: 100%;
  max-width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.App::-webkit-scrollbar {
  display: none;
}

.App-logo {
  width: 30vw;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.sec1 {
  transition: 1.5s all ease !important;
}
.sec2 {
  transition: 2s all ease !important;
}
.sec3 {
  transition: 2.5s all ease !important;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
/* .reveal1 {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1.5s all ease;
}

.reveal1.active {
  transform: translateY(0);
  opacity: 1;
}
.reveal2 {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal2.active {
  transform: translateY(0);
  opacity: 1;
} */

:root {
  --primary: #2b3c48;
  --secondary: #dfd6a7;
  --fullwidth: 100%;
  /* --primary: rgb(1, 54, 96);
  --secondary: #d4ae52; */
}
html {
  font-size: 20px;
  /* font-size: 1.25vw; */
}
@media (max-width: 1300px) {
  html {
    font-size: 16px;
    /* font-size: 1.55vw; */
  }
}
@media (max-width: 900px) {
  html {
    font-size: 14px;
    /* font-size: 1.55vw; */
  }
}
@media (max-width: 650px) {
  html {
    font-size: 14px;
    /* font-size: 1.85vw; */
  }
}
@media (max-width: 400px) {
  html {
    font-size: 11px;
  }
}
