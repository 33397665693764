.AmenitiesBarDiv {
  display: flex;
  width: 100%;
  min-height: 15vh;
  background-size: cover;
  background-position: center;
  place-items: center;
  place-content: center;
  color: var(--primary);
  font-size: 1.4rem;
  font-weight: bold;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.AmenitiesBarDiv .iconDiv {
  display: flex;
  width: 25vw;
  font-size: 0.9rem;
  font-weight: normal;
  justify-content: center;
}
.AmenitiesBarDiv .icon {
  margin-right: 1rem;
  height: 2.5rem;
  width: 2.5rem;
}
.AmenitiesBarDiv .text {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 700px) {
  .AmenitiesBarDiv .iconDiv {
    display: inline-block;
    width: 25vw;
    font-size: 0.9rem;
    font-weight: normal;
  }
  .AmenitiesBarDiv .text {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .AmenitiesBarDiv .icon {
    margin-right: 0rem;
  }
}
