.gallery {
  display: flex;
  flex-direction: column;
  padding: 2px;
  transition: 0.3s;
}
.gallery:hover .gallery__image {
  filter: grayscale(1);
}
.gallery__row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50vh;
}
.gallery__link {
  margin: 2px;
  overflow: hidden;
}
.gallery__link:hover .gallery__image {
  filter: grayscale(0);
}
.gallery__link:hover .gallery__caption {
  opacity: 1;
}
.gallery__thumb {
  width: 33.3vw;
  position: relative;
}
.gallery__image {
  display: block;
  height: 50vh;
  transition: 0.3s;
}
.gallery__image:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.gallery__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 15px 15px;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  color: white;
  opacity: 1;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: 0.3s;
}
@media only screen and (max-width: 500px) {
  .gallery {
    display: flex;
    flex-direction: column;
    padding: 2%;
    transition: 0.3s;
    filter: grayscale(0);
  }
  .gallery:hover .gallery__image {
    /* filter: grayscale(1); */
  }
  .gallery__row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .gallery__link {
    margin: 2px;
    overflow: hidden;
  }
  .gallery__image:hover {
    cursor: pointer;
  }
  .gallery__link:hover .gallery__image {
    filter: grayscale(0);
  }
  .gallery__link:hover .gallery__caption {
    opacity: 1;
  }
  .gallery__thumb {
    width: auto;
    position: relative;
  }
  .gallery__image {
    display: block;
    height: 33vh;
    width: 100%;
    transition: 0.3s;
  }
  .gallery__image:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  .gallery__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 25px 15px 15px;
    width: 100%;
    font-family: "Raleway", sans-serif;
    font-size: 1.5rem;
    color: white;
    opacity: 1;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    transition: 0.3s;
  }
  .activate {
    transform: scale(1);
    filter: grayscale(1);
    transition: 0.3s;
  }

  .activate.active {
    transform: scale(1.1) !important;
    filter: grayscale(0) !important;
    transition: 0.3s;
  }
  .gallery:hover .gallery__image {
    filter: grayscale(1);
  }

  .gallery__link:hover .gallery__image {
    filter: grayscale(1);
  }
  .gallery__link:hover .gallery__caption {
    opacity: 1;
  }
  .gallery__image:hover {
    transform: unset;
  }
}
