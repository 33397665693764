.navbar a {
  color: white;
  text-decoration: inherit;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.5s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.8rem var(--bs-nav-link-padding-x);
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-nav a {
  color: white;
  text-decoration: inherit;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.5s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}
a:hover {
  color: var(--secondary);
}
.bg-black {
  background-color: var(--primary);
}
.navbar {
  display: flow-root;
  transition: 0.25s;
  padding-top: 2.5rem;
  box-shadow: none;
  border-bottom-style: solid;
  border-bottom-color: rgba(79, 79, 79, 0);
}
.navbar .projectHeader {
  box-shadow: 0 10px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.22);

  opacity: 0;
  transition: 0.25s;
  position: fixed;
  top: 10vh;
  display: flex;
  width: 100%;
  height: 10vh;
  background-color: var(--secondary);
  background-size: cover;
  background-position: center;
  place-items: center;
  place-content: center;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
}
.navbFixed {
  transition: 0.25s;
  padding-top: 2.5rem;
  box-shadow: none;
  border-bottom-style: solid;
  border-bottom-color: rgba(79, 79, 79, 0);
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 2.15rem;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url(https://img.icons8.com/ios-glyphs/120/FFFFFF/menu--v1.png) !important;
}
.navbar-dark .navbar-toggler-icon:hover {
  background-image: url(https://img.icons8.com/ios-glyphs/120/9d5c63/menu--v1.png) !important;
}
.offcanvas {
  background-color: var(--primary);
  z-index: 1028;
  font-family: "Qanelas light";
}
.offcanvas-backdrop {
  z-index: 1025;
}
.navbar-brand {
  margin-left: 1rem;
}

.offcanvas-header {
  height: 7.5rem;
}
.navbar-toggler {
  border: none;
  margin-bottom: 2.5%;
  margin-bottom: -1vw;
}
.navbar-toggler:focus {
  border: none !important;
}
@media only screen and (max-width: 700px) {
  .navbar .projectHeader {
    box-shadow: 0 10px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin-top: -3vh;
    opacity: 0;
    transition: 0.25s;
    position: fixed;
    /* top: 10vh; */
    display: flex;
    width: 100%;
    height: 8vh;
    background-color: var(--secondary);
    background-size: cover;
    background-position: center;
    place-items: center;
    place-content: center;
    color: white;
    font-size: 2.4rem;
    font-weight: bold;
  }
  .navbar-nav a {
    font-size: 1.5rem;
    color: white;
    text-decoration: inherit;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.5s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
}
