* {
  box-sizing: border-box;
}
h1 {
  font-weight: normal;
  text-align: left;
  font-size: 8vw;
}

.animationMainDiv {
  width: 100%;
  text-align: left;
}
.animationMainDiv a {
  text-decoration: none;
  font-weight: bold;
  background: linear-gradient(
    135deg,
    rgba(43, 171, 217, 1) 0%,
    rgba(0, 130, 200, 1) 100%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.note::after {
  content: "";
  width: 20%;
  height: 2px;
  border-radius: 999px;
  background-color: #afafaf;
  background: linear-gradient(
    135deg,
    rgba(43, 171, 217, 1) 0%,
    rgba(0, 130, 200, 1) 100%
  );
  position: absolute;
  top: -1.8em;
  left: 0;
}
.note {
  font-size: 0.8em;
  color: #8a8a8a;
  position: relative;
  margin-top: 4em;
}

/* Scroller styling */
.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  width: 6em;
  color: var(--secondary);
}
.scroller > span {
  position: absolute;
  top: 0;
  animation: slide 7s infinite;
  font-weight: bold;
}
@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -1.2em;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}

@media only screen and (max-width: 500px) {
  .divdiv h1 {
    font-weight: normal;
    text-align: left;
    font-size: 10vw;
  }
  .divdiv {
    width: 100%;
    text-align: left;
  }
}
