header {
  width: 100vw;
  background: black;
  overflow: hidden;
}

.image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.7;
}
.sectiontitle {
  font-size: 2rem;
  margin-top: 8vw;
  margin-bottom: 2vw;
}
@media only screen and (max-width: 700px) {
  .sectiontitle {
    font-size: 2rem;
    margin-top: 13vw;
    margin-bottom: 2vw;
  }
}
