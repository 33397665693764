/* .partnerMainDiv {
} */
.partnerMainDiv .imageDiv {
  display: flex;
  width: 100%;
  height: 40vh;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url("https://heritage-eg.s3.amazonaws.com/WebsiteImages/projectsHeader.jpg");
  background-color: var(--primary);
  background-size: cover;
  background-position: center;
  place-items: center;
  place-content: center;
  color: white;
  font-size: 1.5rem;
  padding-top: 8vh;
}
.partnerMainDiv .partnerSecondaryDiv {
  display: flex;
  margin-top: 7rem;
}
