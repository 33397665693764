.aboutUsMainDiv {
}
.aboutUsMainDiv .imageDiv {
  display: flex;
  width: 100%;
  height: 40vh;
  background-image: url("https://heritage-eg.s3.amazonaws.com/WebsiteImages/aboutUsPage.png");
  background-color: var(--primary);
  background-size: cover;
  background-position: center;
  place-items: center;
  place-content: center;
  color: white;
  font-size: 2rem;
}
.aboutUsMainDiv .textDiv {
  width: 100%;
  padding: 7%;
  text-align: justify;
  font-size: 1.1rem;
}
.aboutUsMainDiv .col {
  width: 30%;
  padding: 3%;
  text-align: justify;
  font-size: 1.1rem;
}
.aboutUsMainDiv .tableDiv {
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
}
.aboutUsMainDiv .tableDiv .title {
  width: 100%;
  text-align: -webkit-center;
  margin-bottom: 3%;
}
.aboutUsMainDiv .tableDiv hr {
  width: 80%;
  color: black;
  justify-self: -webkit-center;
}
.aboutUsMainDiv .tableDiv span {
  font-size: 1.5rem;
}

.aboutUsMainDiv .ourTeam {
  margin-top: 7vw;
  padding-left: 7%;
  padding-right: 7%;
  text-align: -webkit-center;
}
.aboutUsMainDiv .ourTeam hr {
  color: black;
  width: 80%;
}
.aboutUsMainDiv .ourTeam .row {
  margin-top: 2vw;
  display: flex;
}

.aboutUsMainDiv .ourTeam .title {
  font-size: 4rem;
  width: 50%;
  text-align: left;
  padding-left: 3%;
}

.aboutUsMainDiv .ourTeam .text {
  font-size: 1.1rem;
  width: 35%;
  text-align: left;
  display: flex;
  align-items: center;
}
.aboutUsMainDiv .ourTeam .cards {
  margin-top: 5vw;
  display: flex;
  width: 100%;
  place-content: space-around;
  height: 35rem;
}
.aboutUsMainDiv .ourTeam .cards .oneCard {
  width: 20rem;
}

@media only screen and (max-width: 700px) {
  .aboutUsMainDiv .tableDiv {
    display: inline;
  }
  .aboutUsMainDiv .col {
    width: 100%;
    padding-left: 7%;
    padding-right: 7%;
  }
  .aboutUsMainDiv .ourTeam .text {
    width: 45%;
  }
  .aboutUsMainDiv .ourTeam .cards {
    display: inline-block;
    margin-bottom: 5vw;
    height: fit-content;
    /* height: 50rem; */
  }
  .aboutUsMainDiv .ourTeam .cards .oneCard {
    height: 42rem;
  }
}
