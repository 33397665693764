.mainDivPartners {
  display: inline-block;
  width: 100%;
  padding-bottom: 3vw;
}
.mainDivPartners .cardsDiv {
  display: flex;
  margin-left: 10%;
  width: 80%;
  justify-content: space-evenly;
  padding-top: 3vw;
  /* padding-bottom: 10vw; */
}

.mainDivPartners .cardsDiv .card {
  background-color: red !important;
  width: 20vw !important;
  height: 20vw !important;
  cursor: pointer;
}
.mainDivPartners .cardsDiv .cardDiv2 {
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
}

.mainDivPartners .text {
  margin-left: 15rem;
  margin-right: 15rem;
  margin-top: 3rem;
  color: #1f2224;
  opacity: 0.8;
  font-size: 1.5rem;
  letter-spacing: 0.08vw;
  text-align: -webkit-center;
}
.mainDivPartners .text2 {
  margin-left: 15rem;
  margin-right: 15rem;
  margin-top: 3rem;
  color: #1f2224;
  opacity: 0.8;
  font-size: 1rem;
  letter-spacing: 0.08vw;
  text-align: -webkit-center;
  display: flex;
  justify-content: center;
}
.mainDivPartners .text hr {
  width: 70%;
  border-top: 1px solid;
  opacity: 0.5;
  margin: 1.5rem 0;
}
.emailme {
  color: var(--primary);
  cursor: pointer;
  text-decoration: none !important;
  font-weight: bold;
}
.logoProjectCard {
  height: 7vw;
  margin-top: 6vw;
}
.divPartnerCard {
  width: 20vw;
  height: 20vw;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  place-content: center;
  text-align: center;
}
.divPartnerCard img {
  width: 60%;
}
.divPartnerCard .viewAll {
  color: var(--primary);
}
.divPartnerCard .textDiv {
  color: white;
  text-align: center;
  line-height: 2rem;
  width: 100%;
}
.divPartnerCard .textDiv .name {
  font-weight: bold;
  font-size: 1.5rem;
  width: 100%;
}
.divPartnerCard .textDiv .slogan {
  font-size: 1rem;
}
.parallax-card-layers {
  border-radius: 0 !important;
}
.parallax-card-shine {
  border-radius: 0 !important;
}
.mainDivPartners .theCard {
  cursor: pointer;

  width: 20vw;
  height: 20vw;
  background-position: center !important;
  background-size: cover !important;
}
.mainDivPartners .atropos-shadow {
  z-index: -1;
  background: #000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(24px);
}
.mainDivPartners .atropos-inner {
  border-radius: 0.3rem;
}
@media only screen and (max-width: 700px) {
  .mainDivPartners .theCard {
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-size: cover !important;
    margin-top: 3vw;
    cursor: pointer;
  }
  .mainDivPartners .divPartnerCard {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .mainDivPartners .atropos {
    perspective: 300px;
    margin-bottom: 2rem;
  }
  .mainDivPartners .cardsDiv {
    margin-left: 0;
    display: inline-block;
    width: 50%;
    justify-content: space-evenly;
    padding-top: 3vw;
    padding-right: 6vw;
    padding-left: 6vw;
  }
  .mainDivPartners .cardsDiv .card {
    background-color: red !important;
    width: 35vw !important;
    height: 60vw !important;
    cursor: pointer;
  }
  .mainDivPartners .parallax-card {
    margin-bottom: 5vw;
  }
  .mainDivPartners .text {
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 2.5rem;
    color: #1f2224;
    opacity: 0.8;
    font-size: 1.2rem;
    letter-spacing: 0.08vw;
  }
  .mainDivPartners .text2 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    color: #1f2224;
    opacity: 0.8;
    font-size: 1rem;
    letter-spacing: 0.08vw;
    text-align: -webkit-center;
    display: flex;
    justify-content: center;
  }
  .mainDivPartners .atropos-shadow {
    z-index: -1;
    background: #000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(24px);
  }
}
