/* body {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
} */
.ham {
  width: 3rem;
  stroke: white;
  z-index: 1090;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: inherit;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.ham6 .top {
  stroke-dasharray: 40 172;
}
.ham6 .middle {
  stroke-dasharray: 40 111;
}
.ham6 .bottom {
  stroke-dasharray: 40 172;
}
.ham6.active .top {
  stroke: white;

  stroke-dashoffset: -132px;
}
.ham6.active .middle {
  stroke: white;

  stroke-dashoffset: -71px;
}
.ham6.active .bottom {
  stroke: white;

  stroke-dashoffset: -132px;
}
