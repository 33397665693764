.projectMainDiv {
}
.projectMainDiv .imageDiv {
  display: flex;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  place-items: center;
  place-content: center;
  color: white;
  font-size: 2rem;
  opacity: 0.8;
}
.projectMainDiv .backgroundDiv {
  background-color: black;
  height: 100vh;
}
.projectMainDiv .backgroundDiv .textDiv {
  color: white;
  position: absolute;
  text-align: left;
  top: 40vh;
  left: 4rem;
  line-height: 5rem;
}
.projectMainDiv .backgroundDiv .textDiv .name {
  font-size: 4.5rem;
}
.projectMainDiv .backgroundDiv .textDiv .slogan {
  font-size: 3.5rem;
}
.projectMainDiv .projectHeader {
  position: fixed;
  top: 10vh;
  display: flex;
  width: 100%;
  height: 10vh;
  background-color: var(--secondary);
  background-size: cover;
  background-position: center;
  place-items: center;
  place-content: center;
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
}
/* .projectMainDiv .summaryDiv {
  text-align: left;
  margin-top: 3rem;
  margin-left: 3rem;
  margin-right: 3rem;
}

.projectMainDiv .summaryDiv .title {
  font-weight: bold;
  font-size: 1.5rem;
} */
@media only screen and (max-width: 700px) {
  .projectMainDiv .backgroundDiv .textDiv {
    color: white;
    position: absolute;
    text-align: center;
    top: 40vh;
    left: 0rem;
    width: 100%;
    line-height: 5rem;
  }
  .projectMainDiv .backgroundDiv .textDiv .name {
    font-size: 5rem;
  }
  .projectMainDiv .backgroundDiv .textDiv .slogan {
    font-size: 2.5rem;
  }
}
