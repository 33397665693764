.employeeCard {
  height: fit-content;
  /* width: 20rem; */
  /* padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%; */
}
.textTitle {
  font-size: 1rem;
  color: var(--primary); /* width: 20rem; */
}
.textTitleDiv {
  display: flex;
  text-align: left;
}
.m-1 {
  color: var(--primary) !important;
  background: white !important;
  transition: color 0.5s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.25s ease-in-out, box-shadow 0.15s ease-in-out !important;
  width: 2.5rem;
  height: 2.5rem;
  border-style: solid !important;
  border-width: 0.125rem !important;
  border-color: white !important;

  /* border: none !important; */
}
.m-1:hover {
  background-color: white;
  color: var(--primary) !important;
  border-color: var(--primary) !important;
  /* border: none !important; */
}
.Main-Div-Footer .icon {
  color: var(--primary) !important;
  margin: 0;
  width: 50% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-duration: 1s;
}
.aboutUsMainDiv .ourTeam .cards .oneCard img {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  filter: saturate(0.75);
}
@media only screen and (max-width: 700px) {
  .textTitle {
  }
  .textTitleDiv {
    display: inline-block;
    text-align: center;
  }
  .contentDiv {
    padding: 1rem !important;
  }
  .employeeCard {
    height: fit-content;
    width: 20rem;
  }
}
