.contactUsMainDiv {
}
.contactUsMainDiv .imageDiv {
  display: flex;
  width: 100%;
  height: 40vh;
  background-image: url("https://heritage-eg.s3.amazonaws.com/WebsiteImages/aboutUsPage.png");
  background-color: var(--primary);
  background-size: cover;
  background-position: center;
  place-items: center;
  place-content: center;
  color: white;
  font-size: 2rem;
}
