.mainDiv {
  transition: 0.25s;
  display: flex;
}
.imgLogo {
  transition: 0.25s;
  width: 4rem;
  height: 4rem;
  margin-right: -0.7rem !important;
}
.imgLogoFixed {
  width: 0rem !important;
  height: 0 !important;
  visibility: hidden;
}

.textDiv {
  align-self: center;
}
.textDiv1 {
  transition: 0.25s;
  font-family: "Qanelas light";
  font-size: 0.7rem;
  margin-top: -0.5rem;
  text-align: left;
  overflow: hidden;
}
.textDiv1Fixed {
  transition: 0.25s;
  font-family: "Qanelas light";
  font-size: 0.7rem;
  margin-top: -0.5rem;
  text-align: left;
  overflow: hidden;
  height: 0 !important;
  width: 2rem !important;
  visibility: hidden !important;
}
/* Change styles for span and cancel button on extra small screens */
@media only screen and (max-width: 500px) {
  .mainDiv {
    margin-left: -1rem;
  }
}
/* Change styles for span and cancel button on medium/small screens */
@media only screen and (min-width: 500px) {
}

/* Change styles for span and cancel button on laptop screens */
@media only screen and (min-width: 700px) {
}
